@import 'node_modules/daterangepicker/daterangepicker';

body {
  .daterangepicker {
    border-radius: 0;
  }

  .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
    border-radius: 0;
  }

  .daterangepicker .calendar-table {
    border-radius: 0;
  }

  .daterangepicker td.start-date {
    border-radius: 0;
  }

  .daterangepicker td.end-date {
    border-radius: 0;
  }

  .daterangepicker td.start-date.end-date {
    border-radius: 0;
  }

  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: var(--market-main);
  }
}


//.daterangepicker {
//  border: none!important;
//  border-radius: 0!important;
//  margin: 0!important;
//  &:before {
//    display: none!important;
//  }
//  .calendar {
//    @include media-breakpoint-only(sm) {
//      max-width: 230px!important;
//    }
//  }
//  .ranges {
//    li {
//      border-radius: 0;
//      color: #000;
//      &:hover {
//        background: $market-main;
//        border: 1px solid $market-main;
//        color: #fff;
//      }
//    }
//    li.active {
//      background: $market-main;
//      border: 1px solid $market-main;
//    }
//  }
//  .range_inputs .btn-sm {
//    border-radius: 0;
//  }
//  td {
//    border-radius: 0!important;
//  }
//  td.active, td.active:hover {
//    background-color: $market-main!important;
//  }
//  .input-mini {
//    border-radius: 0!important;
//  }
//  .input-mini.active {
//    border: 1px solid #000!important;
//  }
//  .input-mini.active:focus {
//    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px!important;
//  }
//  td.off.start-date, td.off.end-date {
//    color: #fff!important;
//    opacity: .5;
//  }
//}