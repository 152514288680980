$market-main: #fe2e29;
$gray-0: #f5f5f5;
$gray-1: #ececf2;
$gray-2: #6b717b;
$gray-3: #3b3c40;
$gray-4: #333333;


.modal-dialog.search-modal-dialog {
  .modal-content {
    background-color: $gray-1;
    border: none;
    border-radius: 0;
    box-shadow: none;
    .modal-header {
      align-items: center;
      border-bottom: 0;
      padding: 20px 20px 10px;
    }
    .modal-title {
      color: $market-main;
      display: flex;
      font-size: 2.4rem;
      justify-content: center;
    }
    .modal-body {
      padding: 10px 20px 20px!important;
    }
    form.searchbox {
      width: 100%;
      .searchbox-input {
        border: none;
        box-shadow: none!important;
        height: 50px;
        padding: 0px 10px;
        outline: none!important;
        font-size: 14px;
      }
    }
  }
}
.modal-backdrop.in {
  opacity: .65;
}