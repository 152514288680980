@import "global";
@import "searchModal";

// =====================================================================================================================
// VARIABLES
//
// <editor-fold>
// =====================================================================================================================
  $market-main: #fe2e29;
  $gray-0: #f5f5f5;
  $gray-1: #ececf2;
  $gray-2: #6b717b;
  $gray-3: #3b3c40;
  $gray-4: #333333;

  :root {
    --market-main: #{$market-main};
    --floating-label-text-input-background-color: #{$gray-0};
    --floating-label-text-input-label-color: #{$gray-2};
    --floating-label-text-input-label-active-color: #{$gray-3};
    --floating-label-text-input-label-background-color: transparent;

    --brand-primary-button-bg-color: #{$market-main};
  }
// </editor-fold>


@import './_floatingLabelTextInput';
@import './_backToTop';
@import './_daterangepicker';

// =====================================================================================================================
// MIXINS
//
// <editor-fold>
// =====================================================================================================================
  @mixin sitewide-breakpoints {
    grid-template-columns: auto 1fr auto;
    column-gap: 10px;
    @include media-breakpoint-up(sm) {
      grid-template-columns: auto 536px auto;
      column-gap: 0;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: auto 728px auto;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: auto 952px auto;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: auto 1140px auto;
    }
  }
  @mixin sitewide-breakpoints-fluid-sm-md {
    grid-template-columns: auto 1fr auto;
    @include media-breakpoint-up(sm) {
      grid-template-columns: auto 576px auto;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: auto 728px auto;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: auto 952px auto;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: auto 1140px auto;
    }
  }
  @mixin sitewide-breakpoints-fluid {
    grid-template-columns: auto 1fr auto;
    @include media-breakpoint-up(sm) {
      grid-template-columns: auto 576px auto;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: auto 768px auto;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: auto 992px auto;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: auto 1180px auto;
    }
  }
  @mixin forms-breakpoints {
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
    @include media-breakpoint-up(sm) {
      grid-template-columns: auto 335px auto;
      column-gap: 0;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: auto 355px auto;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: auto 355px auto;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: auto 355px auto;
    }
  }
  @mixin latest-news-grid-mixin($sm-to-md: 2, $lg: 3) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-direction: row;
    .latest-news-item {
      display: grid;
      grid-template-areas: "image"
                           "information"
                           "actions";
      grid-template-rows: 250px auto;
      grid-template-columns: 100%;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
      @include media-breakpoint-between(sm, md) {
        width: calc(100% / #{$sm-to-md});
        &:nth-child(#{$sm-to-md}n) {
          border-right: none;
        }
        &:nth-child(#{$sm-to-md}n+1) {
          border-left: none;
        }
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% / #{$lg});
        &:nth-child(#{$lg}n) {
          border-right: none;
        }
        &:nth-child(#{$lg}n+1) {
          border-left: none;
        }
      }
      .latest-news__image {
        grid-area: image;
        -ms-grid-row-span: 1;
      }
      .latest-news__image--grid {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 250px;
        width: 100%;
        overflow: hidden;
      }
      .latest-news__image--list {
        display: none;
      }
      .latest-news__information-container {
        grid-area: information;
        margin: 10px 0 0;
        .latest-news__title {
          color: $gray-4;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.35;
          &:hover {
            color: $market-main;
          }
        }
      }
      .news-list-item__information-container {
        display: none;
      }
      .latest-news__actions-container {
        grid-area: actions;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .published {
          font-size: 1.3rem;
          color: $gray-2;
        }
        .latest-news__actions {
          a {
            color: $gray-2;
            font-size: 1.5rem;
            padding: 0 10px 0 0;
            &:hover {
              color: $market-main;
            }
          }
        }
      }
    }
  }

  @mixin latest-news-list-mixin {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-direction: row;
    .latest-news-item {
      display: grid;
      grid-template-areas: "information image" "actions image" "none image";
      grid-template-rows: auto auto;
      grid-template-columns: 65% 35%;
      background-color: $gray-0;
      margin-bottom: 20px;
      padding: 20px;
      width: 100%;
      .latest-news__image {
        grid-area: image;
        -ms-grid-row-span: 1;
        margin-left: 20px;
      }
      .latest-news__image--grid {
        display: none;
      }
      .latest-news__image--list {
        width: 100%;
      }
      .latest-news__information-container {
        display: none;
      }
      .latest-news__actions-container {
        display: none;
      }
    }
  }

  @mixin category-news-grid-mixin {
    .category-news-item {
      display: grid;

      grid-template-areas: "image"
                           "information"
                           "actions";
      grid-template-rows: auto auto;
      grid-template-columns: 100%;
      margin-bottom: 0;
      padding-bottom: 20px;

      @include media-breakpoint-between(sm, md) {
        grid-template-areas: "image information"
                             "image actions"
                             "image none";

        grid-template-rows: auto auto;
        grid-template-columns: 35% 65%;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }

      border-bottom: 1px solid $gray-1;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      @include media-breakpoint-up(sm) {
        grid-template-columns: 30% 70%;
      }
      @include media-breakpoint-up(lg) {
        grid-template-areas: "image"
                             "information"
                             "actions";
        grid-template-rows: auto auto;
        grid-template-columns: 100%;
        margin-bottom: 0;
        padding-bottom: 20px;
      }
      .category-news__image {
        grid-area: image;
        -ms-grid-row-span: 1;
        padding: 0 0 0 10px;
        display: block;

        @include media-breakpoint-up(sm) {
          padding: 0 0 0 20px;
        }
        @include media-breakpoint-up(lg) {
          padding: 0;
        }
      }
      .category-news__image--grid {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        overflow: hidden;
        height: 250px;
        margin-bottom: 10px;

        @include media-breakpoint-between(sm, md) {
          height: 110px;
        }
      }
      .category-news__information-container {
        grid-area: information;
        padding: 0 10px;
        margin: 10px 0;

        @include media-breakpoint-between(sm, md) {
          margin: 0;
        }

        @include media-breakpoint-up(sm) {
          padding: 0 20px;
        }
        @include media-breakpoint-up(lg) {
          margin: 10px 0;
        }

        .category-news__title {
          color: $gray-4;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.3;
          &:hover {
            color: $market-main;
          }
        }
      }
      .category-news__actions-container {
        grid-area: actions;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        @include media-breakpoint-up(sm) {
          padding: 0 20px;
        }
        .pub-date {
          font-size: 1.3rem;
          color: $gray-2;
        }
        .category-news__actions {
          a {
            color: $gray-2;
            font-size: 1.5rem;
            padding: 0 10px 0 0;
            &:hover {
              color: $market-main;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:nth-child(n+2) {
          .category-news__image {
            display: none;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:nth-child(n+2) {
          .category-news__image {
            display: none;
          }
        }
      }
    }
  }

  @mixin release-related-news-grid-mixin($lg: 3) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-direction: row;
    @include media-breakpoint-between(sm, md) {
      display: block;
    }
    .related-news-item {
      display: grid;
      grid-template-areas: "image"
                           "information"
                           "actions";
      grid-template-rows: 250px auto;
      grid-template-columns: 100%;
      margin-bottom: 10px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
      }
      @include media-breakpoint-between(sm, md) {

        grid-template-areas: "image information"
                             "image actions"
                             "image none";
        grid-template-rows: auto auto;
        grid-template-columns: 35% 65%;
      }
      @include media-breakpoint-up(lg) {
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        width: calc(100% / #{$lg});
        &:nth-child(#{$lg}n) {
          border-right: none;
        }
        &:nth-child(#{$lg}n+1) {
          border-left: none;
        }
      }
      .related-news__image {
        grid-area: image;
        -ms-grid-row-span: 1;
        @include media-breakpoint-between(sm, md) {
          margin-right: 20px;
        }
      }
      .related-news__image--grid {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 250px;
        width: 100%;
        overflow: hidden;
        @include media-breakpoint-between(sm, md) {
          height: 110px;
          margin-bottom: 0;
        }
      }
      .related-news__information-container {
        grid-area: information;
        margin: 10px 0;
        .related-news__title {
          color: $gray-4;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.35;
          &:hover {
            color: $market-main;
          }
        }
      }
      .related-news__actions-container {
        grid-area: actions;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .published {
          font-size: 1.3rem;
          color: $gray-2;
        }
        .related-news__actions {
          a {
            color: $gray-2;
            font-size: 1.5rem;
            padding: 0 10px 0 0;
            &:hover {
              color: $market-main;
            }
          }
        }
      }
    }
  }

  @mixin two-tier-channel-grid-mixin($sm-to-md: 2, $lg: 3) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-direction: row;
    .two-tier-channel-item {
      display: grid;
      grid-template-areas: "image"
                           "title";
      grid-template-rows: 115px auto;
      grid-template-columns: 100%;
      margin-bottom: 10px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
      }
      @include media-breakpoint-between(sm, md) {
        width: calc(100% / #{$sm-to-md});
        &:nth-child(#{$sm-to-md}n) {
          border-right: none;
        }
        &:nth-child(#{$sm-to-md}n+1) {
          border-left: none;
        }
      }
      @include media-breakpoint-up(lg) {
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        margin-bottom: 20px;
        width: calc(100% / #{$lg});
        &:nth-child(#{$lg}n) {
          border-right: none;
        }
        &:nth-child(#{$lg}n+1) {
          border-left: none;
        }
      }
      .two-tier-channel-item__image {
        grid-area: image;
        -ms-grid-row-span: 1;
      }
      .two-tier-channel-item__image--grid {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 115px;
        width: 100%;
        overflow: hidden;
      }
      .two-tier-channel-item__information-container {
        background: $gray-4;
        grid-area: title;
        padding: 10px;
        .two-tier-channel-item__title {
          color: $white;
          font-size: 1.8rem;
          font-weight: bold;
          &:hover {
            color: $gray-1;
          }
        }
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// GLOBAL STYLES
//
// <editor-fold>
// =====================================================================================================================
  html, body {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 100%;
  }

  a {
    text-decoration: none!important;
  }

  h1, h2, h3, h4 {
    margin: 0;
  }
  button:focus {
    box-shadow: none!important;
    outline:0!important;
  }
  .btn:focus {
    box-shadow: none!important;
    outline:0!important;
  }

  .edit-popover-link { color: black!important; }

  .alert-danger {
    .close {
      color: #721c24;
      font-size: 2.4rem;
      text-decoration: none!important;
    }
    a {
      color: #721c24!important;
      text-decoration: underline!important;
    }
  }
  .published-at.embargoed {
    font-weight: bold;
  }
  .embargo-danger--100 {
    width: 100%;
  }
// </editor-fold>

// =====================================================================================================================
// LAYOUT
//
// <editor-fold>
// =====================================================================================================================
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout__content {
  flex: 1 0 auto;
}

.layout__footer {
  flex-shrink: 0;
}

// </editor-fold>


// =====================================================================================================================
// BUTTONS
//
// <editor-fold>
// =====================================================================================================================
  @mixin btn-market-border() {
    background: transparent;
    border: 2px solid $market-main;
    border-radius: 0;
    color: $market-main;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 3px 14px;

    &:hover {
      color: $market-main;
    }

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: $market-main;
        color: $white;
      }
    }

    &[aria-expanded="true"] {
      background: $market-main;
      color: $white;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      padding: 7px 20px;
    }
  }


  .btn--market-border {
    background: transparent;
    border: 2px solid $market-main;
    border-radius: 0;
    color: $market-main;
    font-size: 1.3rem;
    font-weight: bold;
    height: 30px;
    padding: 3px 14px;

    &:hover {
      color: $market-main;
    }

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: $market-main;
        color: $white;
      }
    }

    &[aria-expanded="true"] {
      background: $market-main;
      color: $white;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      height: 40px;
      padding: 7px 20px;
    }
  }

  .btn--market-background {
    background: $market-main;
    border: 2px solid $market-main;
    border-radius: 0;
    color: $white;
    font-size: 1.3rem;
    font-weight: bold;
    height: 30px;
    padding: 3px 10px;
    &:hover {
      color: $white;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      height: 40px;
      padding: 7px 14px;
    }
    @include media-breakpoint-up(lg) {
      padding: 7px 20px;
    }
  }

  .btn--gray-1-background {
    background: $gray-1;
    border: 2px solid $gray-1;
    border-radius: 0;
    color: $gray-2;
    font-size: 1.3rem;
    font-weight: bold;
    height: 30px;
    padding: 3px 10px;
    &:hover {
      color: $gray-3;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      height: 40px;
      padding: 7px 14px;
    }
    @include media-breakpoint-up(lg) {
      padding: 7px 20px;
    }
  }

  .btn--gray-2-background {
    background: $gray-2;
    border: 2px solid $gray-2;
    border-radius: 0;
    color: $white;
    font-size: 1.3rem;
    font-weight: bold;
    height: 30px;
    padding: 3px 10px;
    &:hover {
      color: $white;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      height: 40px;
      padding: 7px 14px;
    }
    @include media-breakpoint-up(lg) {
      padding: 7px 20px;
    }
  }

  .btn--white-background {
    background: $white;
    border: 2px solid $white;
    border-radius: 0;
    color: $gray-2;
    font-size: 1.3rem;
    font-weight: bold;
    height: 30px;
    padding: 3px 10px;

    // Desktop
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: $market-main;
      }
    }

    // For mobile, only make the button appear "active" when a dropdown is open
    &[aria-expanded="true"] {
      color: $market-main;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      height: 40px;
      padding: 7px 14px;
    }
    @include media-breakpoint-up(lg) {
      padding: 7px 20px;
    }
  }

  .btn--more {
    color: $gray-2;
    font-size: 1.3rem;
    padding: 0;
    &:hover {
      color: $market-main;
    }
  }

  .custom__dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0px 8px 18px -5px rgba(204,204,204,1);
    margin-top: -1px;
    padding: 10px;
    z-index: 2000;
    .dropdown-item {
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &:hover {
        border: 2px solid $market-main;
        color: $market-main;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:active {
        background-color: $white;
        color: $gray-2;
      }
      i {
        margin-left: 10px;
      }
    }
  }
  .basket__remove {
    color: $market-main!important;
    opacity: 1!important;
    .main-content-action-label {
      color: $market-main;
    }
  }
// </editor-fold>



// =====================================================================================================================
// BUTTONS
//
// <editor-fold>
// =====================================================================================================================
  #daterange-desktop__dropdown {
    border-radius: 0;
    border: none;
    box-shadow: 0px 8px 20px -8px rgba(0,0,0,0.75);
    padding: 10px;
    width: 375px;
    .daterange-desktop__dropdown--inputs {
      display: flex;
    }
    .input-group {
      margin-bottom: 20px;
      &:first-child {
        margin-right: 10px  ;
      }
      .form-control {
        border-color: $gray-1;
        box-shadow: none;
        font-size: 1.3rem;
      }
      .input-group-text {
        border-radius: 0;
        cursor: pointer;
      }
    }
    .btn-group {
      width: 100%;
      .clear-date-filter {
        background-color: $gray-1;
        color: $gray-2;
        margin-right: 10px;
      }
      .apply-date-filter, .clear-date-filter {
        border-radius: 0;
        font-size: 1.3rem;
      }
    }
  }
  #daterange-mobile {
    background-color: $gray-1;
    color: $gray-2;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 15px 10px 0 10px;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
      padding: 15px 20px 0 20px;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
    span {
      font-weight: normal;
    }
    button {
      color: $gray-2;
      font-size: 1.5rem;
    }
  }
// </editor-fold>


// =====================================================================================================================
// NAVBAR
//
// <editor-fold>
// =====================================================================================================================
  .sitewide_header-container {
    display: grid;
    height: 80px;
    @include sitewide-breakpoints;
  }

  .sitewide_header {
    grid-column: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $white;
  }

  .sitewide_header-logo {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .sitewide_header-logo--desktop-container {
    display: flex;
    align-items: center;
  }

  .sitewide_header-logo-img--desktop {
    height: 23px;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .sitewide_header-logo-img--desktop-span {
    color: $gray-4;
    font-size: 1.6rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .sitewide_header-logo-img--mobile {
    height: 23px;
    margin-right: 10px;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .sitewide_header-logo-img--mobile-span {
    color: $gray-4;
    font-size: 1.6rem;
    font-weight: bold;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .menu-toggle-button {
    background: transparent;
    border: none;
    color: $gray-4;
    font-size: 2.4rem;
    margin-right: 20px;
    padding: 0;
    &:hover {
      color: $market-main;
    }
  }

  .sitewide_header-menu-items {
    .sitewide_header-menu-item {
      color: $gray-4;
      font-size: 2rem;
      padding: 0 7px;
      &:hover {
        color: $market-main;

        .sitewide_header-menu-item-link {
          color: $market-main;
        }
      }
      &:first-child {
        padding: 0 7px 0 0;
      }
      &:last-child {
        padding: 0 10px 0 7px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.3rem;
      }
    }
    .sitewide_header-menu-item-link {
      color: $gray-4;
    }
    i {
      color: $gray-4;
      font-size: 2.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }

  .sitewide_nav-container {
    box-shadow: 0 17px 16px rgba(0,0,0, 0.5);
    display: grid;
    height: auto;
    background-color: $white;
    position: absolute;
    width: 100%;
    z-index: 1040;

    @include sitewide-breakpoints;
    @include media-breakpoint-up(lg) {
      box-shadow: none;
      background-color: $market-main;
      height: 40px;
      position: relative;
    }
  }

  .sitewide_nav {
    grid-column: 2;
    display: none;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 1.3rem;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
    }
    &.is-open {
      display: flex;
    }
  }
  .sitewide_nav-item--1 {
    padding: 0;
    @include media-breakpoint-up(lg) {
      padding: 0 15px;
    }
    @include media-breakpoint-down(md) {
      &:last-child {
        padding-bottom: 10px;
      }
    }
  }
  .sitewide_nav-item--2, .sitewide_nav-item--3 {
    padding: 0;
    @include media-breakpoint-up(lg) {
      &:first-child {
        > a {
          padding-top: 10px;
        }
      }

      &:last-child {
        > a {
          padding-bottom: 10px;
        }
      }

    }

    > a {
      color: $gray-2;
      font-weight: lighter;
      padding: 0 0 10px;
      @include media-breakpoint-up(lg) {
        color: $gray-3;
        font-weight: bold;
        padding: 5px 20px;
        flex-direction: column;
        white-space: nowrap;
      }
    }
    &.is-open, &:hover {
      > a { color: $market-main; }
    }
  }
  .sitewide_nav-item--1 > a {
    border-bottom: none;
    color: $gray-4;
    font-size: 1.5rem;
    padding-bottom: 20px;
    @include media-breakpoint-up(lg) {
      color: $white;
      padding-bottom: 0;
    }
  }
  .sitewide_nav-item--1.is-open > a {
    @include media-breakpoint-down(md) {
      padding-bottom: 5px;
    }
  }

  .custom__dropdown-menu--account {
    background-color: $gray-0;
    border: none;
    border-radius: 0;
    box-shadow: 0 8px 18px -5px rgba(204,204,204,1);
    margin-top: -1px;
    padding: 20px;
    text-align: center;
    z-index: 2000;
    top: 56px!important;

    left: 50% !important;
    transform: translateX(-50%) !important;

    .dropdown-item {
      border: none;
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 0!important;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: transparent;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      top: 50px!important;
    }

  }
// </editor-fold>


// =====================================================================================================================
// Dropdown Navigation
//
// <editor-fold>
// =====================================================================================================================
  ul.slimmenu {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    &.collapsed {
      li {
        display: block;
        width: 100%;

        box-sizing: border-box;

        > ul {
          position: static;
          display: none;
        }

        a {
          display: block;
          box-sizing: border-box;
        }

        .sub-toggle {
          height: 40px;
        }
      }
    }

    li {
      align-items: left;
      display: flex;
      flex-direction: column;
      position: relative;

      @include media-breakpoint-up(lg) {
        align-items: center;
        flex-direction: row;
      }

      &.is-open, &:hover {
        background-color: white;
        > a { color: $market-main; }
      }

      ul {
        margin: 0;
        list-style-type: none;
      }

      > ul {
        left: 0;
        top: 100%;
        z-index: 999;
        display: none;
        width: 100%;
        @include media-breakpoint-up(lg) {
          position: absolute;
        }

        li {
          display: block;
        }

        > li ul {
          left: 100%;
          top: 0;
          z-index: 999;
          display: none;
          width: 100%;
          @include media-breakpoint-up(lg) {
            position: absolute;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-up(lg) {
          align-items: flex-start;
        }
      }
    }

    li.has-submenu > ul {
      display: none;
    }

    li.has-submenu.is-open > ul {
      display: block;
      padding-left: 20px;
      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
    }
    @include media-breakpoint-up(lg) {
       li.has-submenu:hover > ul {
        box-shadow: 1px 1px 3px rgba(0,0,0,.2);
        display: block;

      }
    }

    li > ul {
      margin: 0;
      padding: 0;
      width: auto;
      background-color: white;
      @include media-breakpoint-up(lg) {
        // padding: 20px;
      }
    }

    li > ul > li ul {
      width: auto;
    }
  }

// </editor-fold>

// =====================================================================================================================
// HOMEPAGE CAROUSEL
//
// <editor-fold>
// =====================================================================================================================

.tooltip.content-tooltip {
  font-size: 1.4rem;
  text-align: left;
  opacity: 1;

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: #b7b7b7;
      }
    }
  }

  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: #b7b7b7;
      }
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: #b7b7b7;
      }
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: #b7b7b7;
      }
    }
  }

  &.bs-tooltip-auto {
    &[x-placement^="top"] {
      @extend .bs-tooltip-top;
    }
    &[x-placement^="right"] {
      @extend .bs-tooltip-right;
    }
    &[x-placement^="bottom"] {
      @extend .bs-tooltip-bottom;
    }
    &[x-placement^="left"] {
      @extend .bs-tooltip-left;
    }
  }


  .tooltip-inner {
    max-width: 250px;
    padding: 1.5rem;
    text-align: left;
    border-radius: 0;
    border: solid 1px #b7b7b7;
    background-color: white;
    color: black;
    line-height: 1.7rem;
  }

  .arrow {
    border-top-color: #b7b7b7;
  }
}

// </editor-fold>

// =====================================================================================================================
// HOMEPAGE CAROUSEL
//
// <editor-fold>
// =====================================================================================================================
  .homepage_carousel-container {
    background-color: $gray-4;
    display: grid;
    padding: 0;
    @include sitewide-breakpoints-fluid-sm-md;
    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }
    @include media-breakpoint-up(lg) {
      height: 40vw;
      max-height: 556px;
      padding: 40px 0;
    }
  }

  .homepage_carousel {
    grid-column: 2;
    display: grid;
    grid-template-areas: "first";
    grid-template-columns: 100%;
    @include media-breakpoint-up(md) {
      grid-template-areas: "first first"
                         "second third";
      grid-template-columns: 50% 50%;
    }
    @include media-breakpoint-up(lg) {
    grid-template-areas: "first second"
                         "first third";

    grid-template-columns: 70% 30%;
    }
  }

  .homepage_carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;

    &:hover {
      text-decoration: none;
      color: $market-main;
    }
  }

  .homepage_carousel-item--first {
    grid-area: first;
    color: white;
    background-color: #0f0f0f;
    height: 232px;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
      height: 300px;
    }
    @include media-breakpoint-up(md) {
      height: 350px;
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
      height: auto;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  .homepage_carousel-item--second {
    grid-area: second;
    background-color: #0f0f0f;
    font-size: .9rem;
    height: 200px;
    margin-right: 10px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      height: auto;
      margin-bottom: 10px;
      margin-right: 0;
    }
    .homepage_carousel-details {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }

  .homepage_carousel-item--third {
    grid-area: third;
    background-color: #0f0f0f;
    font-size: .9rem;
    height: 200px;
    margin-left: 10px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      height: auto;
      margin-top: 10px;
      margin-left: 0;
    }
    .homepage_carousel-details {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }

  .homepage_carousel-details {
    background-color: rgba(0,0,0,0.55);
    font-size: 1.5rem;
    font-weight: bold;
    padding: 20px;
    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 10px;
    }
    .homepage_carousel-details-date {
      font-size: 1.3rem;
      font-weight: 400;
      margin-top: 5px;
      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        line-height: 1.3;
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// HOMEPAGE LATEST NEWS
//
// <editor-fold>
// =====================================================================================================================
  .homepage_latest-news-container {
    background-color: white;
    display: grid;
    @include sitewide-breakpoints;
  }

  .homepage_latest-news {
    grid-column: 2;
    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }
  }

  .homepage_latest-news--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    @include media-breakpoint-up(md) {
      margin: 20px 0 20px;
    }
    @include media-breakpoint-up(lg) {
      margin: 40px 0 20px;
    }
  }

  .homepage_latest-news--header--title {
    display: flex;
    align-items: center;
    h2 {
      font-size: 2.6rem;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        color: $gray-4;
        font-size: 3.6rem;
      }
    }
  }

  .homepage_latest-news--list-grid-toggle {
    margin-left: 10px;
    margin-top: 2px;
  }

  .homepage_latest-news--grid-indicator {
    margin-right: 5px;
  }

  .homepage_latest-news--grid-indicator,
  .homepage_latest-news--list-indicator {
    color: $gray-2;
    font-size: 2.2rem;
    &:hover {
      color: $market-main;
    }
  }

  .homepage_latest-news--grid-indicator.active,
  .homepage_latest-news--list-indicator.active {
    color: $market-main;
  }

  .homepage_latest-news--grid {
    @include latest-news-grid-mixin(2, 3);
  }
  .homepage_latest-news--list {
    @include latest-news-list-mixin;
  }
// </editor-fold>

// =====================================================================================================================
// HOMEPAGE CATEGORY NEWS
//
// <editor-fold>
// =====================================================================================================================
  .homepage_category-news-container {
    background-color: $gray-1;
    display: grid;
    padding: 10px 0 0;
    @include sitewide-breakpoints;
    @include media-breakpoint-up(sm) {
      padding: 20px 0 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
  }

  .homepage_category-news {
    grid-column: 2;

    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(lg) {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .homepage_category-news-card {
    background-color: $white;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      border-left: solid 10px $gray-1;
      border-right: solid 10px $gray-1;
      &:nth-child(3n) {
        border-right: none;
      }
      &:nth-child(3n+1) {
        border-left: none;
      }
    }
  }
  .homepage_category-news--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  .homepage_category-news-news--header--title {
    h2 {
      font-size: 2.6rem;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        color: $gray-4;
        font-size: 2rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2.6rem;
      }
    }
  }

  .homepage_category-news--list {
    @include category-news-grid-mixin;
  }
// </editor-fold>

// =====================================================================================================================
// HOMEPAGE SOCIAL
//
// <editor-fold>
// =====================================================================================================================
  .homepage_social-container {
    display: grid;
    @include sitewide-breakpoints;
  }

  .homepage_social {
    grid-column: 2;
    padding-bottom: 40px;
  }

  .homepage_social--header {
    margin: 20px 0;
    @include media-breakpoint-up(lg) {
      margin: 40px 0 20px;
    }
    h2 {
      font-size: 2.6rem;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        color: $gray-4;
        font-size: 3.6rem;
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// HOMEPAGE MOBILE SUBSCRIBE
//
// <editor-fold>
// =====================================================================================================================
  .homepage_subscribe-container--mobile {
    display: grid;
    padding-bottom: 10px;
    @include sitewide-breakpoints;
    @include media-breakpoint-up(sm) {
      padding-bottom: 20px;
    }
  }
  .homepage_subscribe--mobile {
    grid-column: 2;
    background: $gray-3;
    padding: 20px 40px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      padding: 40px 0;
    }
  }
  .homepage_subscribe--header--mobile {
    h2 {
      color: $market-main;
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 10px;
      @include media-breakpoint-up(sm) {
        color: $white;
        font-size: 3.6rem;
        margin-bottom: 20px;
      }
    }
  }
  .homepage_subscribe--body--mobile {
    p {
      color: $white;
      font-size: 1.5rem;
      margin-bottom: 10px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// HOMEPAGE SIGN UP
//
// <editor-fold>
// =====================================================================================================================
  .homepage_sign-up-container {
    display: none;
    @include sitewide-breakpoints;
    @include media-breakpoint-up(lg) {
      background-color: $gray-1;
      display: grid;
      padding: 0 0 40px 0;
    }
  }

  .homepage_sign-up {
    grid-column: 2;

    display: grid;
    grid-template-areas: "request subscribe";

    grid-template-columns: 50% 50%;
  }

  .homepage_sign-up-request {
    grid-area: request;
  }

  .homepage_sign-up-subscribe {
    grid-area: subscribe;
  }

  .homepage_sign-up-card {
    background-color: $white;
    border-left: solid 10px $gray-1;
    border-right: solid 10px $gray-1;
    &:nth-child(2n) {
      border-right: none;
    }
    &:nth-child(2n+1) {
      border-left: none;
    }
  }

  .homepage_sign-up--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    h2 {
      font-size: 2.8rem;
      font-weight: bold;
    }
    i {
      color: $market-main;
      margin-right: 10px;
    }
  }

  .homepage_sign-up--body {
    padding: 0 40px 20px 60px;
    p {
      font-size: 1.5rem;
    }
  }
// </editor-fold>

// =====================================================================================================================
// SITEWIDE FOOTER
//
// <editor-fold>
// =====================================================================================================================
  .sitewide_footer-container {
    background-color: $gray-4;
    display: grid;
    padding: 20px 0;
    @include sitewide-breakpoints;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
  }

  .sitewide_footer {
    grid-column: 2;
    color: $white;
  }

  .sitewide_footer-logo {
    height: 25px;
    @include media-breakpoint-up(lg) {
      height: 35px;
    }
  }

  .sitewide_footer-nav--desktop {
    display: none;
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      display: flex;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding-left: 20px;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .sitewide_footer-nav--mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
    ul {
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0;
      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .sitewide_footer-nav-header {
    color: $white;
    font-size: 1.5rem;
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  .sitewide_footer-nav-header a {
    color: $white;
    &:hover {
      color: $market-main;
    }
  }

  .sitewide_footer-link {
    color: white;
    &:hover {
      color: $market-main;
    }
  }

  .sitewide_footer-link--channel-l2 {
    font-size: 1.2rem;
  }

  .sitewide_footer-social {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
    a {
      color: $white;
      font-size: 2.2rem;
      padding: 0 5px;
      &:hover {
        color: $market-main;
      }
      &:first-child {
        padding: 0 5px 0 0;
      }
    }
  }

  .sitewide_footer-legal {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
    a {
      color: $white;
      font-size: 1.2rem;
      padding: 0 5px;
      &:hover {
        color: $market-main;
      }
      &:first-child {
        padding: 0 5px 0 0;
      }
    }
  }

  .sitewide_footer-statement {
    margin-top: 10px;
    font-size: 1.2rem;
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  .sitewide_footer-copyright {
    margin-top: 10px;
    font-size: 1.2rem;
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
// </editor-fold>

// =====================================================================================================================
// SITEWIDE BANNER & TAB BAR
//
// <editor-fold>
// =====================================================================================================================
  .sitewide_banner-container{
    display: grid;
    min-height: 120px;
    padding: 0;
    @include media-breakpoint-up(md) {
      min-height: 170px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 260px;
    }
    @include sitewide-breakpoints-fluid;

    &.sitewide_banner-container--page {
      min-height: unset;
    }

    &.sitewide_banner-container--basket {
      min-height: 120px;
      //@include media-breakpoint-up(md) {
      //  min-height: 150px;
      //}
      @include media-breakpoint-up(lg) {
        min-height: 260px;
      }
    }
  }
  .sitewide_banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 2;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
    }
  }
  .sitewide_banner-header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 30px;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    h1 {
      color: $gray-4;
      font-size: 2.8rem;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        font-size: 4.8rem;
      }
    }
    h2 {
      color: $gray-4;
      font-size: 2.8rem;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        font-size: 3.8rem;
      }
    }
    h4, h5 {
      color: $gray-2;
      font-size: 1.8rem;
      font-weight: lighter;
      @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
      }
    }
    .sitewide_banner-header-actions {
      display: flex;
    }

    &.sitewide_banner-header--basket {
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        align-items: center;
      }
    }
  }
  .sitewide_tab-bar {
    background: transparent;
    display: flex;
    justify-content: space-between;
  }
  .sitewide_tab-bar-nav-tabs {
    border-bottom: none;
    width: 100%;

    .sitewide_tab-bar-nav-item {
      margin: 0;
      &:last-child {
        margin: 0;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 10px 0 0;
      }

      &.sitewide_tab-bar-nav-item--push-right {
        margin-left: auto;
      }

      .sitewide_tab-bar-nav-link {
        background: rgba(236, 236, 242, .4);
        border: none;
        border-radius: 0;
        color: $gray-2;
        font-size: 2rem;
        height: 50px;
        padding: 10px 15px;

        @include media-breakpoint-up(md) {
          font-size: 1.5rem;
          padding: 15px 20px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
          padding: 11px 20px;
        }

        &.sitewide_tab-bar-nav-link--content-tab {
          &:hover {
            &:after {
              content: "";
              border-bottom: 5px solid $market-main;
              display: block;
              position: relative;
              top: 5px;
              @include media-breakpoint-up(md) {
                top: 7px;
              }
            }
          }
        }

        &.active {
          background: $gray-1;
          color: $gray-4;
          opacity: 1;
          font-weight: bold;
        }

        &.sitewide_tab-bar-nav-link--share-basket-btn {
          color: var(--market-main);
          background-color: white;

          @include media-breakpoint-up(md) {
            @include btn-market-border();

            height: 40px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            margin-right: 10px;
            font-weight: 600;
          }

          .tab-bar-button__icon {
            margin-left: 10px;
          }

        }

        &.sitewide_tab-bar-nav-link--clear-basket-btn {
          background-color: var(--market-main);
          margin-right: 10px;
          @include media-breakpoint-up(md) {
            height: 40px;
            padding: 0 20px;
            color: white;
            display: flex;
            align-items: center;
            font-weight: 600;
            margin-right: 20px;
          }
          .tab-bar-button__icon {
            color: white;
            @include media-breakpoint-up(md) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .sitewide_tab-bar-nav-link.active {
    &:after {
      content: "";
      border-bottom: 5px solid $market-main;
      display: block;
      position: relative;
      top: 5px;
      @include media-breakpoint-up(md) {
        top: 7px;
      }
    }
  }
  .sitewide_tab-bar-actions {
    display: flex;
    padding: 0;
    @include media-breakpoint-up(md) {
      padding: 5px 10px 0 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    .btn--market-border--tab-bar {
      border: none;
      display: flex;
      align-items: center;
      margin-right: 0;
      padding: 10px;
      @include media-breakpoint-up(md) {
        border: 2px solid $market-main;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      span {
        margin-right: 5px;
        white-space: nowrap;
      }
      i {
        font-size: 1.8rem;
      }
    }
  }

// </editor-fold>

// =====================================================================================================================
// STANDARD CHANNEL
//
// <editor-fold>
// =====================================================================================================================
  .standard-channel_container {
    display: grid;
    @include sitewide-breakpoints-fluid;
  }
  .standard-channel {
    grid-column: 2;
    margin-bottom: 80px;
  }
  .load-more_container {
    grid-column: 2;
    display: none; // hidden by default - only the :last-child is shown
    justify-content: space-around;
    padding: 20px 0 0 0;
    border-top: 20px solid $gray-1;

    &:last-child {
      display: flex;
    }
  }

  .load-more_container--grid {
    position: absolute;
    bottom: -60px;
    border-top: 0;
    width: 100%;
  }
// </editor-fold>

// =====================================================================================================================
// STANDARD CHANNEL
//
// <editor-fold>
// =====================================================================================================================
  .two-tier-channel_container {
    display: grid;
    @include sitewide-breakpoints;
  }
  .two-tier-channel {
    grid-column: 2;
  }
  .two-tier-channel-grid {
    margin-top: 10px;
    @include two-tier-channel-grid-mixin(2, 3);
    @include media-breakpoint-up(lg) {
      margin-top: 20px;
    }
  }
// </editor-fold>

// =====================================================================================================================
// SEARCH
//
// <editor-fold>
// =====================================================================================================================
  .search-results_message-container {
    padding: 10px;
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
  }
  .search-results_message {
    background: $white;
    height: 40vw;
    padding: 10px;
    @include media-breakpoint-up(md) {
      padding: 20px;
    }
    h4 {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  .search-results_search-input {
    display: flex;
    align-items: center;
    margin-top: 20px;
    input {
      background: $gray-1;
      border: none;
      color: $gray-3;
      height: 42px;
      padding: 5px 10px;
      width: 279px;
    }
    button {
      align-items: center;
      background: $gray-1;
      border: none;
      color: $gray-3;
      display: flex;
      font-size: 1.8rem;
      height: 42px;
      margin-left: 2px;
      padding: 0 12px;
    }
  }
// </editor-fold>

// =====================================================================================================================
// BASKET
//
// <editor-fold>
// =====================================================================================================================
  .basket_actions-container {
    background-color: $gray-1;
    display: grid;
    grid-gap: 10px;
    padding: 10px 10px 0;

    @include media-breakpoint-up(md) {
      padding: 20px 20px 0;
    }

    &.basket_actions-container--releases {
      grid-template-rows: auto;
      grid-template-areas: "download remove";
      grid-template-columns: 1fr 1fr;

      @include media-breakpoint-up(sm) {
        grid-template-areas: "download --spacer-- remove";
        grid-template-columns: max-content auto max-content;
      }

      .basket-actions__action--download-all-releases {
        grid-area: download;
      }

      .basket-actions__action--remove-all-releases {
        grid-area: remove;
      }
    }

    &.basket_actions-container--photos, &.basket_actions-container--videos {
      padding: 0 0 10px;
      @include media-breakpoint-up(lg) {
        padding: 0 0 20px;
      }
    }

    &.basket_actions-container--photos {
      grid-template-rows: auto;
      grid-template-areas: "download remove";
      grid-template-columns: 1fr 1fr;

      @include media-breakpoint-up(sm) {
        grid-template-areas: "download --spacer-- remove";
        grid-template-columns: max-content auto max-content;
      }

      .basket-actions__action--download-all-photos {
        grid-area: download;
      }

      .basket-actions__action--remove-all-photos {
        grid-area: remove;
      }
    }

    &.basket_actions-container--videos {
      display: flex;
      justify-content: flex-end;

      .basket-actions__action--remove-all-photos {
        grid-area: remove;
      }
    }
  }

  .standard-channel.empty-basket {
    background-color: $gray-1;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
      padding-bottom: 0;
    }
  }
  .empty-basket_message-container {
    padding: 10px;
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
  }
  .empty-basket_message {
    background: $white;
    padding: 10px;
    @include media-breakpoint-up(md) {
      padding: 20px;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        font-size: 2.6rem;
      }
    }
    p {
      font-size: 1.3rem;
      margin: 10px 0 0;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
        margin: 20px 0 0;
      }
      i {
        color: $gray-2;
        font-size: 1.8rem;
        margin: 0 5px;
      }
    }
  }
  .share-modal--header {
    h3 {
      font-size: 2rem;
      font-weight: bold;
    }
    .close {
      font-size: 2.4rem;
    }
  }
// </editor-fold>


// =====================================================================================================================
// NEWS LIST
//
// <editor-fold>
// =====================================================================================================================
  .news-list-item.no-image {
    display: grid;
    grid-template-areas: "information";
    grid-template-columns: 100%;
    padding: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
      padding: 20px 20px 0 20px;
    }

    .news-list-item__information-container {
      &.news-list-item__information-container--homepage {
        background-color: $gray-0;
        padding: 0;
      }
    }
  }
  .news-list-item {
    display: grid;
    grid-template-areas: "image"
                         "information";
    grid-template-columns: 100% 100%;
    background-color: $gray-1;
    padding: 10px 10px 0 10px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
      grid-template-areas: "information image";
      grid-template-columns: 75% 25%;
      padding: 20px 20px 0 20px;
    }
  }

  .news-list-item__image-container {
    grid-area: image;
    padding: 10px 10px 0;
    background-color: #fff;
    @include media-breakpoint-up(md) {
      padding: 20px 20px 20px 0;
    }
  }
  .news-list-item__information-container {
    grid-area: information;
    padding: 10px;
    background-color: #fff;
    &.news-list-item__information-container--homepage {
      background-color: $gray-0;
      padding: 0;
    }
    @include media-breakpoint-up(md) {
      padding: 20px 10px 20px 20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
    .news-list-item__title {
      color: $gray-4;
      font-size: 1.5rem;
      font-weight: bold;
      line-height:1.15;
      padding-bottom: 5px;
      &:hover {
        color: $market-main;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        padding-bottom: 10px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.6rem;
      }
    }
    .published {
      color: $gray-2;
      font-size: 1.5rem;
    }
    .news-list-item__summary {
      color: $gray-4;
      font-size: 1.5rem;
      padding-top: 10px;
    }
  }
  .news-list-item__actions-container {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-top: 10px;
    .news-list-item__actions {
      display: flex;
    }
    a {
      color: $gray-2;
      font-size: 2rem;
      padding-right: 10px;
      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
        padding-right: 2px;
      }
    }
    .news-list-item__action {
      margin-left: 5px;
      padding-right: 5px;
      color: $gray-2;
      font-size: 1.4rem;
      @include media-breakpoint-between(md, lg) {
        font-size: 1.3rem;
      }
      &:first-child {
        margin: 0;
      }
    }
    .news-list-item__action.basket__remove {
      color: $market-main;
      margin: 0;
    }

    .news-list-item__action--share {
      margin-right: auto;
      @include media-breakpoint-up(md) {
        margin-right: unset;
      }
    }
  }
  .news-list-item__actions--media {
    margin-left: 5px;
    color: $gray-2;
    a {
      &:last-child {
        padding-right: 0;
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// FORMS
//
// <editor-fold>
// =====================================================================================================================
  .site-form-container {
    display: grid;
    padding: 40px 0;
    @include forms-breakpoints;
    @include media-breakpoint-up(md) {
      padding: 60px 0;
    }
  }
  .site-form {
    grid-column: 2;
  }
  .site-form--header {
    h2 {
      color: $gray-4;
      font-size: 2.8rem;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 3.8rem;
        text-align: left;
      }
    }
    p {
      font-size: 1.4rem;
    }
  }
  .form-input-group {
    margin-bottom: 10px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      color: $gray-2;
      font-size: 1.4rem;
      position: absolute;
      left: 10px;
      top: 15px;
      transition: .2s ease-in-out;
      &.animate-label {
        font-size: 1.2rem;
        top: 0px;
      }
    }
    input {
      background: $gray-0;
      border: none;
      box-shadow: none;
      height: 50px;
      outline: none;
      padding: 20px 15px 5px 15px;
      width: 100%;
    }
    textarea {
      background: $gray-0;
      border: medium none;
      box-shadow: none;
      height: 150px;
      outline: currentcolor none medium;
      padding: 20px 15px 5px;
      width: 100%;
    }
  }
  .site-form--footer-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .site-form-btn {
    margin-top: 20px;
  }
  .site-form--footer-actions--link {
    color: $gray-2;
    font-size: 1.4rem;
    &:hover {
      color: $market-main;
    }
  }
  .form-check {
    color: $gray-2;
    font-size: 1.4rem;
    label {
      margin-left: 5px;
    }
  }
  .terms-of-use__link {
    color: $market-main!important;
  }
  .site-form-cards-container {
    background: $gray-0;
    display: grid;
    padding: 10px 0;
    @include sitewide-breakpoints;
    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }
  }
  .site-form-cards {
    grid-column: 2;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      align-items: center;
      flex-direction: column;
    }
  }
  .site-form-card {
    background: $white;
    margin: 10px 0;
    padding: 20px;
    width: 355px;
    @include media-breakpoint-up(md) {
      margin: 0 10px;
    }
  }
  .site-form-card--header {
    margin-bottom: 10px;
    i {
      color: $market-main
    }
    h4 {
      color: $gray-4;
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  .site-form-card--message {
    color: $gray-4;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .site-form-card--actions {
    text-align: center;
  }
// </editor-fold>

// =====================================================================================================================
// RELEASE SHOW
//
// <editor-fold>
// =====================================================================================================================
  .sitewide_banner.sitewide_banner--release-show {
    padding: 0 10px;
    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    @include media-breakpoint-down(sm) {
      .sitewide_tab-bar-nav-tabs {
        justify-content: flex-start;
        width: 100%;
      }
      .sitewide_tab-bar-nav-link {
        height: 40px;
        padding: 10px 25px;
      }
      .sitewide_tab-bar-nav-link.active::after {
        top: 0;
      }
      .release-show--tab-label {
        font-size: 1.5rem;
      }
    }

  }
  .sitewide_banner-header.media-release_banner-header {
    display: block;
    padding: 20px 0;
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    h2 {
      font-size: 2.2rem;
      @include media-breakpoint-up(md) {
        font-size: 2.8rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 3.8rem;
      }
    }
    h4 {
      font-size: 1.8rem;
      padding-top: 10px;
      @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
      }
    }
    h5 {
      font-size: 1.6rem;
      padding-top: 10px;
      @include media-breakpoint-up(lg) {
        font-size: 2.6rem;
      }
    }
  }
  .media-release_banner-header--title {
    color: $market-main;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  .standard-channel_container--release-show {
    display: grid;
    @include sitewide-breakpoints-fluid-sm-md;

    @include media-breakpoint-down(sm) {
      .grid__item__actions--container {
        display: none!important;
      }
    }

  }
  .release-show_container {
    background-color: $gray-1;
    grid-column: 2;
    display: grid;
    grid-template-areas: "release"
                           "side-content";
    grid-template-columns: 100%;
    padding: 10px;
    @include media-breakpoint-up(lg) {
      grid-template-areas: "release side-content";
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 20px;
      padding: 20px;
    }
  }
  .release-show_main-content {
    grid-column: release / side-content;
    background-color: $white;
    padding: 10px;
    @include media-breakpoint-up(md) {
      padding: 20px;
    }

    @include media-breakpoint-up(xl) {
      grid-column: release;
    }

    &.release-show_main-content--full-width {
      grid-column: release / side-content;
    }
  }
  .release-body, .page-show_container {
    // ================================================
    // Styles that specifically target the release body
    // ================================================
    a {
      color: $market-main;
      &:hover {
        text-decoration: underline!important;
      }
    }
  }
  .release-show_side-content {
    grid-area: side-content;
    background: transparent;
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
  .release_related-news-container {
    background-color: $white;
    display: grid;
    @include sitewide-breakpoints;
  }

  .release_related-news--grid {
    @include release-related-news-grid-mixin(3);
  }

  .release_related-news--header {
    margin: 20px 0;
  }
  .main-content-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .pub-date {
      color: $gray-4;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  .main-content-actions {
    display: flex;
    align-items: center;
  }

  .main-content-action {
    display: flex;
    align-items: center;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }
  }

  .social-share-icon-link {
    margin-right: 5px;
    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  .main-content-action-label {
    color: $gray-4;
    font-size: 1.5rem;
    font-weight: bold;

    margin-right: 5px;
  }

  .main-content-action-link {
    color: $gray-2;
    font-size: 1.8rem;
    margin-left: 5px;
    display: flex;
    align-items: center;
    &:hover {
      color: $market-main;
    }
  }
  .x-svg--black {
    fill: $gray-2;
    &:hover {
      fill: #000000;
    }
  }
  .x-svg--red {
    fill: $gray-2;
    &:hover {
      fill: $market-main;
    }
  }

  .main-content--key-points {
    background-color: $gray-0;
    margin-bottom: 20px;
    padding: 20px 20px 20px 0;
    ul {
      margin: 0;
      li {
        color: $gray-4;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  .main-content--body {
    p {
      font-size: 1.5rem;
      margin: 0;
    }
  }
  .main-content--body--img-title {
    background-color: $gray-1;
    font-size: 1.5rem;
    font-style: italic;
    padding: 10px;
  }
  .release_related-media-container {
    background-color: $white;
    margin-bottom: 0;
    margin-top: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
      margin-top: 0;
    }
    .carousel-control-prev, .carousel-control-next {
      font-size: 2.5rem;
    }
  }
  .release_related-media-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .release_related-media-header--title {
    color: $gray-4;
    font-size: 1.8rem;
    font-weight: bold;
    span {
      color: $gray-2;
      font-weight: normal;
    }
  }
  .release_related-media-header-link {
    color: $gray-2;
    font-size: 2rem;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $market-main;
    }

    &.release_related-media-header-link--disabled {
      color: $gray-1;
      cursor: not-allowed;
    }
  }
  .release_related-media-img {
    img {
      width: 100%;
    }
  }
  .release_related-media-title {
    a {
      font-weight: bold;
    }
  }
  .release_related-media-body {
    padding: 10px;
    a {
      color: $gray-4;
      font-size: 1.5rem;
      &:hover {
        color: $market-main;
      }
    }
  }
  .release_related-media--documents, .release_related-media--links {
    a {
      display: flex;
      margin-bottom: 10px;
      word-break: normal;
      &:last-child {
        margin-bottom: 0;
      }
      i {
        font-size: 1.8rem;
        margin-right: 5px;
      }
    }
  }
  .release_related-media--contact {
    font-size: 1.3rem;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $market-main;
      font-size: 1.3rem;
    }
    .release_related-media--contact-name {
      font-size: 1.5rem;
    }
  }

  .release-media-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      grid-gap: 15px;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: max-content max-content auto;
      margin-bottom: 20px;
    }
  }

  .release_related-galleries {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px;
    padding: 0 10px 10px 10px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
  }

  .release_related-gallery {
    display: grid;
    grid-template-areas: "thumbnail"
                         "title"
                         "photo-count";

    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;

    color: black;
    font-weight: bold;

    &:hover {
      color: $market-main;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: min-content auto;
      grid-template-rows: min-content min-content;

      grid-template-areas: "thumbnail title"
                           "thumbnail photo-count";
    }

  }

  .release_related-gallery__thumbnail {
    width: 100%;
    grid-area: thumbnail;

    @include media-breakpoint-up(lg) {
      width: 120px;
    }
  }

  .release_related-gallery__title {
    grid-area: title;
    padding-top: 5px;
    line-height: 2rem;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-left: 10px;
    }
  }

  .release_related-gallery__photo-count {
    grid-area: photo-count;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
      padding-left: 10px;
    }
  }

  .release-boilerplate {
    h3 {
      font-weight: bold;
      margin-bottom: 0.25em;
      margin-top: 2em;
    }
  }

// </editor-fold>

// =====================================================================================================================
// PHOTO/VIDEO GRID
//
// <editor-fold>
// =====================================================================================================================
  .channel-tab--photo, .channel-tab--photos, .channel-tab--photo-galleries, .channel-tab--video, .channel-tab--videos {
    padding: 10px;
    background-color: $gray-1;
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
  }

  .grid {
    margin-bottom: -2.75%;
    @include media-breakpoint-up(md) {
      margin-bottom: -1.25%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: -1.75%;
    }
    /* reveal grid after images loaded */
    &.are-images-unloaded {
      opacity: 0;
    }

    .grid__item, .grid__col-sizer {
      width: 48.5%;
      @include media-breakpoint-up(md) {
        width: 32.5%;
      }
      @include media-breakpoint-up(lg) {
        width: 23.70%;
      }
    }

    .grid__gutter-sizer {
      width: 2.75%;
      @include media-breakpoint-up(md) {
        width: 1.25%;
      }
      @include media-breakpoint-up(lg) {
        width: 1.75%;
      }
    }

    .grid__item {
      background-color: $white;
      margin-bottom: 2.75%;
      display: grid;
      grid-template-areas: "thumbnail"
                           "information";
      grid-template-rows: auto auto;
      @include media-breakpoint-up(md) {
        margin-bottom: 1.25%;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.75%;
      }
      img {
        display: block;
        width: 100%;
      }
      .grid__item__thumbnail {
        grid-area: thumbnail;
      }
      .grid__item__overlay {
        grid-area: thumbnail;
        .grid__item__play-button {
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: $white;
          font-size: 5rem;
          height: 100%;
          opacity: .55;
          &:hover {
            opacity: 1;
          }
        }
      }

      .grid__item__download-overlay {
        z-index: 2;
        grid-row: thumbnail / information;
        grid-column: thumbnail / information;
        background-color: rgba(255,255,255,0.95);
        padding: 20px;
        display: none;
      }
      .grid__item__download-overlay.active {
        display: block;
      }
      .download-overlay__header {
        color: $gray-4;
        display: flex;
        justify-content: space-around;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .download-overlay__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
        padding: 0;
        a {
          color: $market-main;
          font-size: 1.3rem;
          @include media-breakpoint-up(md) {
            font-size: 1.5rem;
          }
        }
      }
      .download-overlay__cancel {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        a {
          color: $gray-2;
          font-size: 1.5rem;
        }
      }

      .grid__item__info {
        grid-area: information;
        line-height: 1.35;
        padding: 10px;
      }
      .grid__item__title {
        color: $gray-4;
        font-size: 1.5rem;
        font-weight: bold;
      }
      .grid__item__actions--container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .published-at, .photo-count {
          color: $gray-2;
          font-size: 1.5rem;
        }
      }
      .grid__item__actions {
        a {
          color: $gray-2;
          font-size: 1.8rem;
          padding: 0 2px;
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
// </editor-fold>

// =====================================================================================================================
// PHOTO/VIDEO MODALS
//
// <editor-fold>
// =====================================================================================================================
  .modal-preview-area {
    @include media-breakpoint-up(sm) {
      max-height: 500px;
    }
    @include media-breakpoint-up(md) {
      max-height: 800px;
    }
  }

  .media-modal {
    border: none;
    border-radius: 0;
    img {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    .modal-body {
      padding: 0;
    }
    .modal-footer {
      border: none;
    }
    .modal-actions__container {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 0;
    }
    .modal-actions__button {
      background: rgba(0,0,0,.5);
      border: none;
      color: $white;
      font-size: 2.2rem;
      margin-bottom: 3px;
      opacity: .5;
      padding: 5px 10px;
      text-align: center;
      &:hover {
        color: $market-main;
        opacity: 1;
      }
      &[disabled="disabled"] {
        &:hover {
          cursor: not-allowed;
          opacity: .5;
          color: rgba(255,255,255,0.5);
        }
      }
    }
    .modal-footer {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .modal__title {
      color: $gray-4;
      font-size: 1.8rem;
      font-weight: bold;
    }
    .modal__caption {
      color: $gray-4;
      font-size: 1.3rem;
    }
    .modal-footer__social {
      display: flex;
      a {
        color: $gray-2;
        font-size: 2rem;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $gray-4;
        }
      }
    }
  }
// </editor-fold>


// =====================================================================================================================
// PAGES MODALS
//
// <editor-fold>
// =====================================================================================================================
  .standard-channel_container--page-show {
    display: grid;
    @include sitewide-breakpoints-fluid-sm-md;
  }
  .page-show_container {
    grid-column: 2;
    padding: 10px;
    @include media-breakpoint-up(lg) {
      padding: 20px;
    }
  }
  .standard-channel--page-show {
    background-color: $gray-1;
  }
  .page-show_main-content {
    background-color: $white;
    padding: 20px;
  }
// </editor-fold>


// =====================================================================================================================
// License Agreement Modal
//
// <editor-fold>
// =====================================================================================================================
#licenseModal .modal-dialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
// </editor-fold>