@import '_bootstrap';

@import 'fontawesome-pro-5.10.2-web/scss/fontawesome';
$fa-font-path: "fontawesome-pro-5.10.2-web/webfonts";

@import 'fontawesome-pro-5.10.2-web/scss/solid';
@import 'fontawesome-pro-5.10.2-web/scss/regular';
@import 'fontawesome-pro-5.10.2-web/scss/light';
@import 'fontawesome-pro-5.10.2-web/scss/duotone';
@import 'fontawesome-pro-5.10.2-web/scss/brands';

@import '../modules/newsroom/app/assets/stylesheets/_pagePreviewIndicator';

@import 'util';

:root {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}

.basket-action-hidden { display: none!important; }

