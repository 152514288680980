// =====================================================================================================================
// Youtube Video Container - Wrap Youtube embeds in .youtube-video-container to make them responsive
// =====================================================================================================================
.youtube-video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  margin-top: 20px;
}
.youtube-video-container iframe,
.youtube-video-container object,
.youtube-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no-transition {
  transition: none!important;
}

/* "Golden Ratio" Images */
.golden-image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.golden-image--62 {
  padding-top: 62%;
}

.golden-image--50 {
  padding-top: 50%;
}