// =====================================================================================================================
// Back to Top
//
// <editor-fold>
// =====================================================================================================================
.back-to-top-corner {
  position: fixed;
  top: calc(100vh - 30px);
  left: calc(100vw - 30px);
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  color: white;
  border: none;
  align-items: center;
  justify-content: center;
  display: none;

  @include media-breakpoint-up(md) {
    top: calc(100vh - 50px);
    left: calc(100vw - 50px);
    width: 50px;
    height: 50px;
    font-size: 2rem;
  }

  &.is-enabled {
    background-color: var(--brand-primary-button-bg-color);
    opacity: 0.8;
    transition: opacity 250ms;
    display: flex;

    &:hover {
      opacity: 1;
    }
  }
}
// </editor-fold>